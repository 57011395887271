#root {
    padding-left: 236px;
}

main {
    display: flex;
    flex-direction: column;
    padding-left: 164px;
    padding-right: 400px;
    color: #1f0e3d;
}

h1,
.title-highlight {
    color: #4693f8;
}

h2:not(.title-highlight) {
    color: #1f0e3d;
}

img {
    max-width: 100%;
}

hr {
    margin: 30px 0;
    color: #c7dffd;
}

pre {
    font-size: 12px;
    margin: 0;
    padding: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    border-radius: 4px;
    background:#1f0e3d;
    overflow-wrap: break-word;
    font-family: monospace;
    font-weight: 600;
    color:#fff;
}

.home table {
    box-sizing: border-box;
    background-color: #f6faff;
    border: 1px solid  #a1a1a1;
    border-collapse: collapse;
    color: #6f7390;
}

.home table > tbody > tr:hover {
    background-color: #e6eaf3;
}

.home table th {
    border-bottom: 1px solid #a1a1a1;
}

.home table th,
.home table td {
    padding: 12px;
}
