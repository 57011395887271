.env-selection-guide {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.env-selection-guide > .row {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
}