nav {
  position: fixed;
  left: 0;
  width: 236px;
  height: 100%;
  background: #2c0040;
  color: #fff;
}

nav .involves-logo-container {
  text-align: center;
  padding: 16px 8px;
}

nav > aside > div {
  padding: 0 8px;
}

nav a {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.3s;
}

nav a.active {
  background-color: rgba(232, 234, 236, 0.10);
}

nav a:hover {
  background-color: rgba(232, 234, 236, 0.18);
}
