.swagger-ui {
  color: #6f7390;
}

.swagger-ui .filter-container.override-default .operation-filter-input {
  border-color: #bdc7d3;
}
.swagger-ui .filter-container:not(.override-default) {
  display: none;
}

.swagger-ui .wrapper {
  padding-left: 0;
  padding-right: 0;
}

/* GET */
.swagger-ui .opblock.opblock-get {
  border-color: #4693f8;
}
.swagger-ui .opblock.opblock-get .opblock-summary-method,
.swagger-ui .opblock.opblock-get .tab-header .tab-item.active h4 span::after {
  background-color: #4693f8;
}

/* POST */
.swagger-ui .opblock.opblock-post {
  border-color: #00a6af;
}
.swagger-ui .opblock.opblock-post .opblock-summary-method,
.swagger-ui .opblock.opblock-post .tab-header .tab-item.active h4 span::after {
  background-color: #00a6af;
}

/* PUT */
.swagger-ui .opblock.opblock-put {
  border-color: #008672;
}
.swagger-ui .opblock.opblock-put .opblock-summary-method,
.swagger-ui .opblock.opblock-put .tab-header .tab-item.active h4 span::after {
  background-color: #008672;
}

/* PATCH */
.swagger-ui .opblock.opblock-patch {
  border-color: #99ee22;
}
.swagger-ui .opblock.opblock-patch .opblock-summary-method,
.swagger-ui .opblock.opblock-patch .tab-header .tab-item.active h4 span::after {
  background-color: #99ee22;
}

/* DELETE */
.swagger-ui .opblock.opblock-delete {
  border-color: #fc4949;
}
.swagger-ui .opblock.opblock-delete .opblock-summary-method,
.swagger-ui .opblock.opblock-delete .tab-header .tab-item.active h4 span::after {
  background-color: #fc4949;
}

/* Endpoints generic styles */
.swagger-ui .opblock.opblock-get,
.swagger-ui .opblock.opblock-post,
.swagger-ui .opblock.opblock-put,
.swagger-ui .opblock.opblock-delete,
.swagger-ui .opblock.opblock-patch,
.swagger-ui .opblock-summary {
  background-color: #f6faff !important;
  border-color:  #a1a1a1 !important;
}

/* Endpoints tags */
.swagger-ui .opblock-tag {
  color: #1f0e3d;
}

/* Endpoints path */
.swagger-ui .opblock.opblock-get .opblock-summary-path span {
  color: #443c63;
}

/* Endpoints description */
.swagger-ui .opblock-description-wrapper p,
.swagger-ui .opblock-external-docs-wrapper p,
.swagger-ui .opblock-title_normal p {
  color: #6f7390;
}

/* Parameters */
.swagger-ui .parameter__name,
.swagger-ui .parameter__type {
  color: #6f7390;
}

/* Required and Deprecated indicators */
.swagger-ui .parameter__name.required > span,
.swagger-ui .parameter__name.required::after,
.swagger-ui .parameter__deprecated {
  color: #fc4949 !important;
}

/* Responses */
.swagger-ui .response-col_status,
.swagger-ui .response-col_links,
.swagger-ui table thead tr td,
.swagger-ui table thead tr th,
.swagger-ui .tab li {
  color: #6f7390;
}

/* Version control select and message */
.swagger-ui .response-control-examples__title,
.swagger-ui .response-control-media-type__title {
  visibility: hidden;
} 
.swagger-ui .response-control-examples__title,
.swagger-ui .response-control-media-type__title:after{
  content: 'MediaType/Accept-Version';
  visibility: visible;
  display: block;
  margin-bottom: .2em;
  font-size: 15px;
} 
.swagger-ui .response-control-media-type--accept-controller select {
  border-color: inherit;
}
.swagger-ui .response-control-media-type__accept-message {
  color: #00b7c2;
}

/* Endpoints code blocks */
.swagger-ui .opblock-body pre {
  background-color: #1f0e3d;
}

/* Model box */
.swagger-ui .model-box {
  width: 100%;
}
.swagger-ui table.model tr:not(:last-child) {
  border-bottom: 1px solid #a1a1a1;
}
.swagger-ui table.model tbody tr td:first-of-type,
.swagger-ui table.model tbody tr td {
  padding: 8px 0 8px 24px;
}
.swagger-ui table.model tbody tr td .renderedMarkdown > p {
  margin: 0;
  font-size: 14px;
  font-family: monospace;
  color: #3b4151;
}

/* Models list */
.swagger-ui section.models {
  display: none;
}